import "firebase/firestore";
import firebase from "firebase/app";
const db = firebase.firestore();
const collectionRef = db.collection("transactions");
const users = db.collection("users");

export function GetAllPayments() {
  return new Promise((resolve, reject) => {
    const data = [];
    const query = collectionRef.where("clientID", "!=", "");
    query
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const clientId = doc.data().clientID.id;
          let clientName = "";

          try {
            const clientDoc = await users.doc(clientId).get();

            if (clientDoc.exists) {
              clientName = clientDoc.data().display_name;
            } else {
              console.log("Client not found");
            }
          } catch (error) {
            console.error("Error fetching client data:", error);
          }
          console.log(doc.data());
          data.push({
            id: doc.id,
            createdAt: nanosecondsToISO8601(doc.data().created_at.seconds),
            ...doc.data(),
            clientName,
            clientId
          }); //contains the document data
        });
        resolve(data);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}
function nanosecondsToISO8601(seconds) {
  const milliseconds = seconds * 1000; // Convert nanoseconds to milliseconds
  const date = new Date(milliseconds);
  return date;
}
