<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import * as payments from "@/services/payments.js";
import moment from "moment";
import appConfig from "@/app.config";
import Table from "@/components/elements/Tables";
export default {
  page: {
    title: "All Payments",
    meta: [{ name: "Payments", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Table },
  created() {
    payments.GetAllPayments().then((data) => {
      console.log(data);
      this.tableData = data;
    });
  },

  data() {
    return {
      tableData: [],
      title: this.$t("menuitems.payments.text"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.payments.text"),
        },
        {
          text: this.$t("all"),
          active: true,
        },
      ],

      filterOn: [],
      sortBy: "createdAt",

      fields: [
        { label: this.$t("client_name"), key: "clientName", sortable: false },
        { label: this.$t("service"), key: "type", sortable: false },
        {
          label: this.$t("amount"),
          key: "amount",
          sortable: true,
          formatter: (value) => {
            return value.toFixed(2);
          },
        },
        {
          label: this.$t("date"),
          key: "createdAt",
          sortable: true,
          formatter: (value) => {
            return moment(value).format("YYYY-MM-DD HH:mm");
          },
        },
      ],
    };
  },
  computed: {
    flattenedtabletData() {
      return this.tableData.map((contract) => {
        // Flatten the object structure and return a new object
        return {
          clientName: contract.clientName||'ــــــــ',
          clientId:contract.clientId,
          type: contract.type,
          amount: contract.amount,

          createdAt: contract.createdAt,
          // Include other properties you want to flatten
        };
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Table
      :tableData="flattenedtabletData"
      :fields="fields"
      :sortBy="sortBy"
      :filterOn="filterOn"
      :hide-show-more="true"
      :filter-keys="['clientName']"
    />
  </Layout>
</template>
